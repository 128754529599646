import React from 'react';
import { Card, Button, AutoComplete, Input, Table } from 'antd';
import { PlusOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { notification } from 'antd';
import cookie from 'react-cookies'

const { TextArea } = Input;

const openNotification = (title, text) => {
    notification.info({
        message: title,
        description: text,
        placement: 'top',
    });
};

const App = (props) => {
    ///////////////////////////////////////////////////////////////////////////////////
    //查詢學生ID的動作
    const [search_student_id, set_search_student_id] = useState(null);
    const [user_data, set_user_data] = useState({
        user_id: null,
        user_name: null,
        stupro_phone: null,
        user_email: null,
    });

    const get_student_ID = (searchText) => {
        set_search_student_id(searchText);
        fetch(process.env.REACT_APP_API_URL + '/select_user_id?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    // console.log(data);
                    set_user_data(data['result'][0])
                }
                else if (data['status'] === 401) {
                    props.handleLogout()
                }
                else {
                    set_user_data({
                        user_id: null,
                        user_name: null,
                        stupro_phone: null,
                        user_email: null,
                    })

                }
                const options = data['result'].map((item) => ({ value: item['user_id'] }));
                // setAutoCompleteOptions(options);
                return options
            })
    }
    ///////////////////////////////////////////////////////////////////////////////////
    //初始化 載入目前時間
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        // 在组件挂载和更新时更新时间
        const intervalId = setInterval(() => {
            let objectDate = new Date();
            let day = objectDate.getDate();
            let month = objectDate.getMonth();
            let year = objectDate.getFullYear();

            let hour = objectDate.getHours();
            let min = objectDate.getMinutes();
            let sec = objectDate.getSeconds();

            let now_time = `${year}-${month + 1}-${day}  ${hour}:${min}:${sec}`;

            setCurrentTime(now_time);
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    ///////////////////////////////////////////////////////////////////////////////////
    //新增設備到預借設備清單的部分

    //設定預借設備清單的欄位
    const [row_data, set_row_data] = useState([]);
    const columns = [
        {
            title: '設備編號',
            dataIndex: 'serialno',
            key: 'serialno',
        },
        {
            title: '設備名稱',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '配件',
            dataIndex: 'accessory',
            key: 'accessory',
        },
        {
            title: '備註',
            key: 'status',
            dataIndex: 'status',
        },
    ];
    //設定按下預借設備清單按鈕的函式
    const query_device = () => {
        set_select_equipment_id_value('')
        if (select_equipment_data.length === 1) {
            let add_device_info = {
                key: row_data.length + 1,
                'serialno': select_equipment_data[0]['serialno'],
                'name': select_equipment_data[0]['name'],
                'accessory': select_equipment_data[0]['accessory'],
                'status': select_equipment_data[0]['accessory'],
            }
            if (!row_data.some(item => item.serialno === add_device_info.serialno)) {
                let newData = [...row_data, add_device_info];
                set_row_data(newData);
            }
        }
    }


    ///////////////////////////////////////////////////////////////////////////////////
    //確認借出的部分
    const insert_device = () => {
        // console.log(user_data);
        // console.log(data);
        // console.log(borrow_remark);
        // console.log(currentTime);
        fetch(process.env.REACT_APP_API_URL + '/new_equipment', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            },
            body: JSON.stringify({
                user_id: user_data['user_id'],
                data: row_data,
                borrow_remark: borrow_remark,
                currentTime: currentTime,
            })
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    // set_user_data(data['result'][0])
                    set_select_equipment_id_Options([]);
                    set_borrow_remark('');
                    set_select_equipment_id_value('');
                    set_row_data([]);
                    set_user_data({});
                    set_search_student_id(null);
                    openNotification('Success', '借出成功')
                }
                else if (data['status'] === 204) {
                    openNotification('fail', '借出失敗')
                }
                else if (data['status'] === 400) {
                    openNotification('fail', 'MSG_MISSING_FIELD')
                }
                else if (data['status'] === 401) {
                    props.handleLogout()
                }
                else {
                    openNotification('ERROR!', '發生未知錯誤')
                }
            })
    }



    ///////////////////////////////////////////////////////////////////////////////////
    //取消借出的部分


    const Cancel_insert_device = () => {
        set_select_equipment_id_Options([]);
        set_borrow_remark('');
        set_select_equipment_id_value('');
        set_row_data([]);
        set_user_data({});
        set_search_student_id(null);
    }




    ///////////////////////////////////////////////////////////////////////////////////
    //輸入設備號碼或掃碼的部分
    const [select_equipment_id_options, set_select_equipment_id_Options] = useState([]);
    const [select_equipment_data, set_select_equipment_data] = useState([]);
    const [select_equipment_id_value, set_select_equipment_id_value] = useState('');

    const handle_equipment_id_change = (value) => {
        set_select_equipment_id_value(value);
        select_equipment_id(value);
    }


    const select_equipment_id = (searchText) =>
        fetch(process.env.REACT_APP_API_URL + '/select_equipment_id?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                // console.log(res);
                return res.json()
            }) /*把request json化*/
            .then(data => {
                // console.log(data['result']);
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    // console.log(data);
                    // set_user_data(data['result'][0])
                    if (data['result'].length !== 1) {
                        const options = data['result'].map((item) => ({ value: item['serialno'] }));
                        set_select_equipment_id_Options(options);
                        set_select_equipment_data(data['result']);
                    }
                    else {
                        const options = data['result'].map((item) => ({ value: item['serialno'] }));
                        set_select_equipment_id_Options(options);
                        set_select_equipment_data(data['result']);
                        set_select_equipment_id_value('')
                        let add_device_info = {
                            key: row_data.length + 1,
                            'serialno': data['result'][0]['serialno'],
                            'name': data['result'][0]['name'],
                            'accessory': data['result'][0]['accessory'],
                            'status': data['result'][0]['accessory'],
                        }
                        if (!row_data.some(item => item.serialno === add_device_info.serialno)) {
                            let newData = [...row_data, add_device_info];
                            set_row_data(newData);
                        }
                    }
                }
                else if (data['status'] === 401) {
                    props.handleLogout()
                }
            })


    ///////////////////////////////////////////////////////////////////////////////////
    //儲存借出備註的部分
    const [borrow_remark, set_borrow_remark] = useState(null);


    ///////////////////////////////////////////////////////////////////////////////////
    // 勾選Table的資料時的動作
    const [selectedRows, setSelectedRows] = useState([]);
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    ///////////////////////////////////////////////////////////////////////////////////
    //刪除Table的資料時的動作
    const deleteRow = () => {
        const dataSource = [...row_data];
        let new_dataSource = dataSource.filter(item =>
            !selectedRows.some(row => row.key === item.key)
        );
        console.log(new_dataSource);
        set_row_data(new_dataSource);
    };


    ///////////////////////////////////////////////////////////////////////////////////
    //網頁呈現的部分
    return (
        <div style={{
            display: 'flow',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
        }}>
            <div>
                學號：
                <AutoComplete
                    style={{
                        width: 200,
                    }}
                    // options={autoCompleteOptions}
                    placeholder="輸入學號"
                    value={search_student_id}
                    onChange={get_student_ID}
                />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '50%',
                margin: '1%',
            }}>
                <Card
                    title="借用人"
                    size='small'
                    style={{
                        width: '50%',
                        height: '100%',

                    }}
                >
                    <p>姓名：{user_data['user_name'] ? user_data['user_name'] : <span style={{ color: '#888888' }}>  無資料</span>}</p>
                    <p>學號：{user_data['user_id'] ? user_data['user_id'] : <span style={{ color: '#888888' }}>  無資料</span>}</p>
                    <p>信箱：{user_data['user_email'] ? user_data['user_email'] : <span style={{ color: '#888888' }}>  無資料</span>}</p>
                    <p>電話：{user_data['stupro_phone'] ? user_data['stupro_phone'] : <span style={{ color: '#888888' }}>  無資料</span>}</p>
                </Card>

                <Card
                    title="借用資料"
                    size='small'
                    style={{
                        width: '100%',
                        height: '100%',
                        marginLeft: '2%'
                    }}
                >
                    <label>借用時間：</label>
                    <input type="text" value={currentTime} disabled />
                    <p>借出備註：</p>
                    <TextArea rows={3} onChange={(event) => set_borrow_remark(event.target.value)} />
                </Card>
            </div>
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Card
                        title="欲借設備："
                        size='small'
                        style={{
                            width: '100%',
                        }}
                    >
                        設備編號：
                        <AutoComplete
                            style={{
                                width: 200,
                            }}
                            placeholder="輸入設備編號或掃碼"
                            onChange={handle_equipment_id_change}
                            options={select_equipment_id_options}
                            onSearch={handle_equipment_id_change}
                            value={select_equipment_id_value}
                        />
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={query_device}
                            style={{
                                marginLeft: '1%'
                            }}
                        />
                        <Button
                            type="primary"
                            onClick={deleteRow}
                            style={{
                                position: 'absolute',
                                right: 0,
                                marginRight: '1%',
                                backgroundColor: 'red',
                                color: 'white',
                            }}>
                            <CloseCircleTwoTone />刪除
                        </Button>
                        <Table
                            rowKey="key"
                            columns={columns}
                            dataSource={row_data}
                            rowSelection={{
                                ...rowSelection,
                            }}
                        />
                    </Card>
                </div>
                <div style={{
                    textAlign: 'center',
                    margin: '1%',
                }}>
                    <Button
                        type="primary"
                        icon={<CheckCircleTwoTone />}
                        onClick={insert_device}
                        style={{
                            marginLeft: '1%'
                        }}>
                        設備借用
                    </Button>

                    <Button
                        icon={<CloseCircleTwoTone />}
                        onClick={Cancel_insert_device}
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            marginLeft: '1%'
                        }}>
                        取消借用
                    </Button>
                </div>
            </div>
        </div>
    )
};
export default App;
