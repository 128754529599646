import React  from 'react';
import { Tabs } from 'antd';
import Borrow_equipment_page from './borrow_equipment_page';
import Return_equipment_page from './return_equipment_page';

const { TabPane } = Tabs;

const App = (props) => {
  
const items = [
  {
    label: '設備借用',
    key: 'borrow_equipment',
    children: <Borrow_equipment_page handleLogout={props.handleLogout}/>,
  },
  {
    label: '設備歸還',
    key: 'return_equipment',
    children: <Return_equipment_page handleLogout={props.handleLogout}/>,
  },
];


  return (
    <Tabs defaultActiveKey="borrow_equipment" items={items}>
      {items.map((item) => (
        <TabPane key={item.key} tab={item.label}>
          {item.children}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default App;