import React from 'react';
import { Card, AutoComplete, Table, Button, Modal, notification } from 'antd';
import { useState, useEffect } from 'react';
import cookie from 'react-cookies'
import CSVReader from 'react-csv-reader'

const App = (props) => {

    ///////////////////////////////////////////////////////////////////////////////////
    //進入畫面時的動作，先查詢一次資料
    useEffect(() => {
        select_student_profile('')
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////
    //設定Table的欄位

    const columns = [
        {
            title: '學號',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: '名稱',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: '信箱',
            dataIndex: 'user_email',
            key: 'user_email',
        },
        {
            title: '電話',
            key: 'stupro_phone',
            dataIndex: 'stupro_phone',
        },
    ];

    ///////////////////////////////////////////////////////////////////////////////////
    //查詢設備資料
    const [select_equipment_data, set_select_equipment_data] = useState([]);

    const select_student_profile = (searchText) =>
        fetch(process.env.REACT_APP_API_URL + '/get_student_profile?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_select_equipment_data(data['result']);
                }
                else if (data['status'] === 401) {
                    props.handleLogout()
                }
                else {
                    set_select_equipment_data([]);
                }
            })

    ///////////////////////////////////////////////////////////////////////////////////
    // 勾選Table的資料時的動作
    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };
    //////////////////////////////////////modal/////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////////////

    //匯入資料的modal彈出頁面
    const [import_modal_visible, set_import_modal_visible] = useState(false);
    const [csvData, setCsvData] = useState([]);

    const import_modal_page = () => {
        return (
            <Modal
                title="匯入學生資料"
                visible={import_modal_visible}
                onOk={import_modal_handleOk}
                okText="確認"
                onCancel={import_modal_handleCancel}
            >
                <CSVReader
                    onFileLoaded={(data, fileInfo) => {
                        console.log(data)
                        console.log(fileInfo)
                        handleFileLoaded(data)
                        // set_import_modal_visible(false);
                    }}
                />
                <Card
                    size="small"
                    style={{
                        width: "100%",
                    }}
                >
                    <Table columns={columns} dataSource={csvData} />
                </Card>
            </Modal>
        )
    }

    const [successNum, setSuccessNum] = useState(0);

    const import_modal_handleOk = e => {
        // 迴圈逐一新增學生檔案資料到資料庫中
        let successCount = 0;
        for (let i = 0; i < csvData.length; i++) {
            const user_id = csvData[i]['user_id'];
            const user_name = csvData[i]['user_name'];
            const user_email = csvData[i]['user_email'];
            const stupro_phone = csvData[i]['stupro_phone'];

            fetch(process.env.REACT_APP_API_URL + '/add_student_profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'jwtToken': cookie.load('jwtToken'),
                },
                body: JSON.stringify({ 
                    'user_id':user_id, 
                    'user_name':user_name, 
                    'user_email':user_email, 
                    'stupro_phone':stupro_phone }),
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    // 新增成功，做些什麼事情
                    successCount++;
                    if (successCount === csvData.length) {
                        setSuccessNum(successCount);
                    }
                })
                .catch(error => {
                    // 新增失敗，做些什麼事情
                });
        }

        if (successNum === csvData.length) {
            notification.success({
                message: '新增成功',
                description: '已成功匯入 ' + successNum + ' 筆資料\n重載頁面中...',
                placement: 'top',
                onClose: () => {
                    setTimeout(() => {
                        window.location.reload(); // 重新載入整個頁面
                    }, 1000); // 在 1000 毫秒 (1 秒) 後執行
                },
            });
        }
        else {
            notification.error({
                message: '新增失敗',
                description: '預計新增 ' + csvData.length + ' 筆，成功新增 ' + successNum + ' 筆' + '，失敗 ' + (csvData.length - successNum) + ' 筆',
                placement: 'top',
            });
        }
    };

    const import_modal_handleCancel = e => {
        console.log(e);
        set_import_modal_visible(false);
    };
    const handleFileLoaded = (data) => {
        // 取出欄位名稱
        const headers = data[0];

        // 將資料轉換成物件陣列
        const records = data.slice(1).map((record) => {
            const obj = {};
            for (let i = 0; i < headers.length; i++) {
                if (record[i] === undefined || record[i] === '') continue;
                obj[headers[i]] = record[i];
            }
            return obj;
        });
        if (records[records.length - 1]['user_id'] === undefined) records.pop();

        // 更新狀態
        // set_select_equipment_data(records);
        setCsvData(records)
        console.log(records);
    };


    ///////////////////////////////////////////////////////////////////////////////////

    //匯入資料的modal彈出頁面
    const [delete_modal_visible, set_delete_modal_visible] = useState(false);

    const delete_modal_page = () => {
        return (
            <Modal
                title="確認是否刪除以下學生資料"
                visible={delete_modal_visible}
                onOk={delete_modal_handleOk}
                okText="確認"
                onCancel={delete_modal_handleCancel}
            >
                <Card
                    size="small"
                    style={{
                        width: "100%",
                    }}
                >
                    <Table columns={columns} dataSource={selectedRows} />
                </Card>
            </Modal>
        )
    }

    const delete_modal_handleOk = e => {
        // 迴圈逐一刪除學生檔案資料到資料庫中
        let successCount = 0;
        for (let i = 0; i < selectedRows.length; i++) {
            const user_id = selectedRows[i]['user_id'];

            fetch(process.env.REACT_APP_API_URL + '/delete_student_profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'jwtToken': cookie.load('jwtToken'),
                },
                body: JSON.stringify({ 
                    'user_id':user_id, 
                     }),
            })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    // 新增成功，做些什麼事情
                    successCount++;
                    if (successCount === selectedRows.length) {
                        setSuccessNum(successCount);
                    }
                })
                .catch(error => {
                    // 新增失敗，做些什麼事情
                });
        }

        if (successNum === selectedRows.length) {
            notification.success({
                message: '刪除成功',
                description: '已成功刪除 ' + successNum + ' 筆資料\n重載頁面中...',
                placement: 'top',
                onClose: () => {
                    setTimeout(() => {
                        window.location.reload(); // 重新載入整個頁面
                    }, 1000); // 在 1000 毫秒 (1 秒) 後執行
                },
            });
        }
        else {
            notification.error({
                message: '新增失敗',
                description: '預計刪除 ' + selectedRows.length + ' 筆，成功刪除 ' + successNum + ' 筆' + '，失敗 ' + (csvData.length - successNum) + ' 筆',
                placement: 'top',
            });
        }
    };

    const delete_modal_handleCancel = e => {
        console.log(e);
        set_delete_modal_visible(false);
    };


    ///////////////////////////////////////////////////////////////////////////////////
    //畫面HTML
    return (
        <div style={{
            display: 'flow',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%'
        }}>
            <div>
                關鍵字：
                <AutoComplete
                    style={{
                        width: "22%",
                        marginRight: '50%'
                    }}
                    placeholder="可輸入學號、姓名、信箱、電話"
                    onChange={select_student_profile}
                />
                <Button size={'small'} style={{ marginRight: '1%' }} onClick={() => set_import_modal_visible(true)}>匯入</Button>
                {/* <Button size={'small'} style={{ marginRight: '1%' }} onClick={null}>新增</Button>
                <Button size={'small'} style={{ marginRight: '1%' }} onClick={null}>修改</Button> */}
                <Button size={'small'} style={{ marginRight: '1%' }} onClick={() => set_delete_modal_visible(true)}>刪除</Button>

                {import_modal_page()}
                {delete_modal_page()}
            </div>
            <br />
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Card
                        // title="欲借設備："
                        size='small'
                        style={{
                            width: '100%',
                        }}
                    >
                        <Table
                            rowKey="user_id"
                            columns={columns}
                            dataSource={select_equipment_data}
                            rowSelection={{
                                ...rowSelection,
                            }}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
};
export default App;