import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Image } from 'antd';
import { useEffect } from 'react';
import cookie from 'react-cookies'

import backgroundImage from './background.jpg';
import logo_system from './logo_system.png'

export default function Login(props) {
    const navigate = useNavigate();
    useEffect(() => {
        let token = cookie.load('jwtToken')
        // console.log(token)
        if (token) {
            props.setIsLoggedin(true);
          navigate('/menu/borrow_return');
        }
      }, []);


    return (
        <div style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={props.onLogin}
                // onFinishFailed={onFinishFailed}
                className="login-form"
                style={{
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '300px',
                }}
            >
                <Image
                    src={logo_system}
                    width={200}
                />
                <div style={{color:'#fff', fontSize:24}}>NKUST-IC 設備借用系統</div>
                <br/>
                <div>
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                </div>
                <Form.Item >
                    <Button type="primary" htmlType="submit">
                        登入
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}