import React from 'react';
import { useNavigate, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { notification } from 'antd';
import cookie from 'react-cookies';
import sha512 from 'crypto-js/sha512';

import Menu from './system/menu';
import Login_page from './system/login_page';

const openNotification = (text) => {
  notification.warning({
    message: 'ERROR',
    description: text,
    placement: 'top',
  });
};

function App() {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const navigate = useNavigate();
  // let token = localStorage.getItem('jwtToken')
  let token = cookie.load('jwtToken')


  useEffect(() => {
    document.title = '設備借用系統';
    token = cookie.load('jwtToken')
    console.log(window.location.pathname);
    console.log(window.location.pathname === '/');
    if (token) {
      setIsLoggedin(true);
      // const lastVisitedRoute = localStorage.getItem('lastVisitedRoute') || '/menu/borrow_return';
      const lastVisitedRoute = cookie.load('lastVisitedRoute') || '/menu/borrow_return';
      navigate(lastVisitedRoute);
      console.log("navigate(lastVisitedRoute)");
    } else if (window.location.pathname === '/') {
      console.log("navigate('/login')");
      navigate('/login');
    }
  }, []);




  function handleLogin(values) {
    let jwtToken = null;

    fetch(process.env.REACT_APP_API_URL + '/Login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'jwtToken': token,
      },
      body: JSON.stringify({
        username: values.username,
        password: sha512(values.password).toString(),
      }),
    })
      .then((response) => {
        const headers = response.headers;
        jwtToken = headers.get('jwtToken'); // 获取 headers 中的 Token 值
        return response.json();
      })
      .then((data) => {
        console.log('Success:', data);
        if (data['status'] === 200) {
          cookie.save('jwtToken', jwtToken, { path: '/' })
          setIsLoggedin(true);
          navigate('/menu/borrow_return');
          // const lastVisitedRoute = localStorage.getItem('lastVisitedRoute') || '/menu/borrow_return';
          // navigate(lastVisitedRoute);
        } else {
          openNotification('登入失敗');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        openNotification('發生未知錯誤');
      });
  }

  function handleLogout() {
    setIsLoggedin(false);
    navigate('/login');
    cookie.remove('jwtToken', { path: '/' })
    console.log('logout');
  }

  return (
    <Routes>
      <Route path='/login' element={<Login_page onLogin={handleLogin} setIsLoggedin={setIsLoggedin} />} />
      <Route
        path='/menu/*'
        element={
          isLoggedin ? (
            <Menu handleLogout={handleLogout} />
          ) : (
            <Navigate to='/login' />
          )
        }
      />
      {/* <Route path='/*' element={<Navigate to='/menu/borrow_return' />} /> */}
    </Routes>
  );
}

export default App;