import React from 'react';

import { Button, Select, Table, Modal, Input } from 'antd';
import { notification } from 'antd';
import { useState, useEffect } from 'react';
import cookie from 'react-cookies'

import { PlusSquareTwoTone, EditTwoTone, CloseSquareTwoTone } from '@ant-design/icons';

const App = (props) => {

    ///////////////////////////////////////////////////////////////////////////////////
    //進入畫面時的動作，先查詢一次資料
    useEffect(() => {
        select_location()
    }, [])

    ///////////////////////////////////////////////////////////////////////////////////
    //設定Table的欄位

    const columns = [
        {
            title: '位置',
            dataIndex: 'location_name',
            key: 'location_name',
        },
        {
            title: '所在地區',
            dataIndex: 'region_name',
            key: 'region_name',
        },
    ];

    ///////////////////////////////////////////////////////////////////////////////////
    //設定Table的欄位

    const location_options=[
        {
          value: '1',
          label: '燕巢',
        },
        {
          value: '2',
          label: '建工',
        },
        {
          value: '3',
          label: '其他',
        },
      ]

    ///////////////////////////////////////////////////////////////////////////////////
    //查詢為位置資料
    const [location_data, set_location_data] = useState([]);

    const select_location = () =>
        fetch(process.env.REACT_APP_API_URL + '/get_location', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_location_data(data['result']);
                }
                else if (data['status'] === 401) {
                    props.handleLogout()
                }
            })




    ///////////////////////////////////////////////////////////////////////////////////
    // 勾選Table的資料時的動作
    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };



    ///////////////////////////////////////modal////////////////////////////////////////////
    //點選新增按鈕時的動作
    const [new_location_visible, set_new_location_visible] = useState(false);
    const [new_location_name, set_new_location_name] = useState('');
    const [new_region_name, set_new_region_name] = useState(1);

    const New_location_page = () => {

        return (
            <div>
                <Modal
                    title="新增位置"
                    visible={new_location_visible}
                    onOk={handle_add_Ok}
                    onCancel={handle_add_Cancel}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '80%' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>位置</div>
                                <div style={{ width: '70%' }}>
                                    <Input
                                        placeholder="請輸入位置名稱"
                                        onChange={(e) => {
                                            set_new_location_name(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>所在地</div>
                                <div style={{ width: '70%' }}>
                                    <Select defaultValue="1" options={location_options} onChange={(e)=>{
                                        set_new_region_name(e)
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
    //新增位置資料
    const handle_add_Ok = () => {
        fetch(process.env.REACT_APP_API_URL + '/new_location', {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            },
            body: JSON.stringify({
                'region_name': new_region_name,
                'location_name': new_location_name,
            })
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_new_location_visible(false);
                    notification.success({
                        message: '新增成功',
                        description: '已成功新增位置',
                        placement: 'top',
                        onClose: () => {
                            setTimeout(() => {
                              window.location.reload(); // 重新載入整個頁面
                            }, 1000); // 在 1000 毫秒 (1 秒) 後執行
                          },
                    });
                }
                else if(data['status'] === 204){
                    notification.error({
                        message: '新增失敗',
                        description: '請檢查輸入的資料',
                        placement: 'top',
                    });
                }
                else if(data['status'] === 400){
                    notification.error({
                        message: '新增失敗',
                        description: 'MSG_MISSING_FIELD',
                        placement: 'top',
                    });
                }
                else if(data['status'] === 409){
                    notification.error({
                        message: '新增失敗',
                        description: 'MSG_type_EXISTS',
                        placement: 'top',
                    });
                }
                else{
                    notification.error({
                        message: '新增失敗',
                        description: '未知錯誤，請聯絡管理員',
                        placement: 'top',
                    });
                }
            })
    };

    const handle_add_Cancel = () => {
        set_new_location_visible(false);
    };

    ///////////////////////////////////////modal////////////////////////////////////////////
    //點選修改按鈕時的動作
    const [update_location_visible, set_update_location_visible] = useState(false);
    const [update_location_name, set_update_location_name] = useState('');
    const [update_region_name, set_update_region_name] = useState(1);

    const update_location_page = () => {

        return (
            <div>
                <Modal
                    title="修改分類"
                    visible={update_location_visible}
                    onOk={handle_update_Ok}
                    onCancel={handle_update_Cancel}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '80%' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>編號</div>
                                <div style={{ width: '70%' }}>
                                    <Input
                                        placeholder="請輸入編號"
                                        onChange={(e) => {
                                            set_update_location_name(e.target.value)
                                        }}
                                        defaultValue={selectedRows[0]?selectedRows[0]['location_name']:''}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>類別</div>
                                <div style={{ width: '70%' }}>
                                <Select defaultValue="1" options={location_options} onChange={(e)=>{
                                        set_update_region_name(e)
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
    //修改位置資料
    const handle_update_Ok = () => {
        fetch(process.env.REACT_APP_API_URL + '/update_location', {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            },
            body: JSON.stringify({
                'id':selectedRows[0]['id'],
                'region_name': update_region_name,
                'location_name': update_location_name,
            })
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_update_location_visible(false);
                    notification.success({
                        message: '修改成功',
                        description: '已成功修改分類',
                        placement: 'top',
                        onClose: () => {
                            setTimeout(() => {
                              window.location.reload(); // 重新載入整個頁面
                            }, 1000); // 在 1000 毫秒 (1 秒) 後執行
                          },
                    });
                }
                else if(data['status'] === 204){
                    notification.error({
                        message: '修改失敗',
                        description: '請檢查輸入的資料',
                        placement: 'top',
                    });
                }
                else{
                    notification.error({
                        message: '修改失敗',
                        description: '未知錯誤，請聯絡管理員',
                        placement: 'top',
                    });
                }
            })
    };

    const handle_update_Cancel = () => {
        set_update_location_visible(false);
    };

    ///////////////////////////////////////modal////////////////////////////////////////////
    //點選刪除按鈕時的動作
    const [delete_location_visible, set_delete_location_visible] = useState(false);

    const delete_location_page = () => {

        return (
            <div>
                <Modal
                    title="刪除分類"
                    visible={delete_location_visible}
                    onOk={handle_delete_Ok}
                    onCancel={handle_delete_Cancel}>
                        <p>確定刪除該分類</p>
                </Modal>
            </div>
        )
    }
    //修改位置資料
    const handle_delete_Ok = () => {
        fetch(process.env.REACT_APP_API_URL + '/delete_location', {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            },
            body: JSON.stringify({
                'id': selectedRows[0]?selectedRows[0]['id']:'',
            })
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_delete_location_visible(false);
                    notification.success({
                        message: '刪除成功',
                        description: '已成功刪除分類',
                        placement: 'top',
                        onClose: () => {
                            setTimeout(() => {
                              window.location.reload(); // 重新載入整個頁面
                            }, 1000); // 在 1000 毫秒 (1 秒) 後執行
                          },
                    });
                }
                else if(data['status'] === 204){
                    notification.error({
                        message: '刪除失敗',
                        description: '請檢查輸入的資料',
                        placement: 'top',
                    });
                }
                else{
                    notification.error({
                        message: '刪除失敗',
                        description: '未知錯誤，請聯絡管理員',
                        placement: 'top',
                    });
                }
            })
    };

    const handle_delete_Cancel = () => {
        set_delete_location_visible(false);
    };

    ///////////////////////////////////////modal////////////////////////////////////////////

    const handleOk = () => {
        props.set_handle_location_visible(false);
    };

    const handleCancel = () => {
        props.set_handle_location_visible(false);
    };

    ///////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
            <Modal
                title="分類管理"
                visible={props.handle_location_visible}
                onOk={handleOk}
                onCancel={handleCancel}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button size={'small'} style={{ marginRight: '1%' }} onClick={() => set_new_location_visible(true)}><PlusSquareTwoTone />新增</Button>
                    <Button size={'small'} style={{ marginRight: '1%' }} onClick={()=>set_update_location_visible(true)}><EditTwoTone />修改</Button>
                    <Button size={'small'} style={{ marginRight: '1%' }} onClick={()=>set_delete_location_visible(true)}><CloseSquareTwoTone />刪除</Button>
                </div>
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={location_data}
                    rowSelection={{
                        ...rowSelection,
                    }}

                />
            </Modal >
            {New_location_page()}
            {update_location_page()}
            {delete_location_page()}
        </div>
    )
}
export default App;