import React from 'react';
import { DesktopOutlined, TeamOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Card } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import cookie from 'react-cookies'

import Borrow_return from './borrow_return/borrow_return_page';
import History_page from './history/history_page';
import Equipment_management_page from './equipment_management/equipment_management_page';
import Student_profile from './student_profile/student_profile_page';
import Change_password_page from './change_password/change_password_page';
import NotFound from './NotFound';

const { Header, Content, Footer, Sider } = Layout;


function getItem(label, key, icon, children, path) {
  return {
    key,
    icon,
    children,
    label,
    path: `${path}`,
  };
}

const items = [
  getItem('設備管理', 'sub1', <DesktopOutlined />, [
    getItem('借用與歸還', 'borrow_return', null, null, '/menu/borrow_return'),
    getItem('歷史紀錄', 'history', null, null, '/menu/history'),
    getItem('設備管理', 'equipment_management', null, null, '/menu/equipment_management'),
  ]),
  getItem('人員管理', 'sub2', <TeamOutlined />, [
    getItem('學生資料', 'student_profile', null, null, '/student_profile'),
    getItem('修改密碼', 'password', null, null, '/password'),
  ]),
  getItem('登出', 'logout', <DesktopOutlined />, null, '/logout'),
];

const App = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hash.substr(1).startsWith('/menu/') && !(window.location.hash.substr(1).endsWith('/menu/'))) {
      console.log("stay on current page");
    } else {
      console.log("navigate('/menu/borrow_return')");
      navigate('/menu/borrow_return');
    }
  }, []);

  useEffect(() => {
    // localStorage.setItem('lastVisitedRoute', window.location.hash.substr(1));
    cookie.save('lastVisitedRoute', window.location.hash.substr(1));
    console.log('setlastVisitedRoute: ', window.location.hash.substr(1));
  }, [window.location.hash.substr(1)]);

  const getBreadcrumbItem = () => {
    console.log(window.location.hash.substr(1));
    switch (window.location.hash.substr(1)) {
      case '/':
        return '首頁';
      case '/menu/borrow_return':
        return '借用與歸還';
      case '/menu/history':
        return '歷史紀錄';
      case '/menu/equipment_management':
        return '設備管理';
      case '/menu/student_profile':
        return '學生資料';
      case '/menu/password':
        return '修改密碼';
      case '/menu/logout':
        return '登出';
      case '/menu/7':
        return '';
      default:
        return 'NotFound';
    }
  };

  const onSelectMenu = ({ key }) => {
    if (key === 'logout') {
      props.handleLogout();
    }
    else {
      navigate(key);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        // collapsible
        // collapsed={collapsed}
        // onCollapse={(value) => setCollapsed(value)}
        style={{ position: 'fixed', left: 0, top: 0, bottom: 0, zIndex: 999 }}
      >
        <Card
          style={{
            height: 32,
            margin: 16,
            background: 'rgba(255, 255, 255, 0.2)',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          設備借用系統
        </Card>
        <Menu
          theme="dark"
          defaultSelectedKeys={['borrow_return']}
          mode="inline"
          items={items}
          onSelect={onSelectMenu}
        />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: '0 16px', paddingLeft: collapsed ? 80 : 200, height: '100%' }}>
          <Breadcrumb style={{ margin: '6px 0' }}>{getBreadcrumbItem()}</Breadcrumb>
          <Card style={{ height: '100%' }}>
            {/* {console.log(cookie.load('lastVisitedRoute').substr(6))} */}
            <Routes >
              <Route path="borrow_return" element={<Borrow_return handleLogout={props.handleLogout} />} />
              <Route path="history" element={<History_page handleLogout={props.handleLogout} />} />
              <Route path="equipment_management" element={<Equipment_management_page handleLogout={props.handleLogout} />} />
              <Route path="student_profile" element={<Student_profile handleLogout={props.handleLogout} />} />
              <Route path="password" element={<Change_password_page handleLogout={props.handleLogout} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Card>
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}></Footer> */}
      </Layout>
    </Layout>
  );
};

export default App;
