import React, { useRef } from 'react';
import { Card, Button, AutoComplete, Table, Modal, Input, notification, Select } from 'antd';
import { useState, useEffect } from 'react';
import cookie from 'react-cookies'
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print'
import html2canvas from 'html2canvas';

import { ToolTwoTone, PlusSquareTwoTone, EditTwoTone, CloseSquareTwoTone } from '@ant-design/icons';

import Type_management_page from './type_management_page';
import Location_management_page from './location_management_page';



const App = (props) => {

    ///////////////////////////////////////////////////////////////////////////////////
    //進入畫面時的動作，先查詢一次資料
    useEffect(() => {
        select_equipment_id('')
        category_options_onSearch('')
        region_options_onSearch('')
        location_options_onSearch('')
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////
    //設定Table的欄位

    const columns = [
        {
            title: '編號',
            dataIndex: 'serialno',
            key: 'serialno',
        },
        {
            title: '類別',
            dataIndex: 'type_name',
            key: 'type_name',
        },
        {
            title: '名稱',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '地區',
            key: 'location_name',
            dataIndex: 'location_name',
        },
        {
            title: '位置',
            key: 'region_name',
            dataIndex: 'region_name',
        },
        {
            title: '狀態',
            key: 'status',
            dataIndex: 'status',
        },
    ];

    ///////////////////////////////////////////////////////////////////////////////////
    //設定category的options欄位
    const [category_options, set_category_options] = useState([]);

    const category_options_onSearch = (searchText) => {
        fetch(process.env.REACT_APP_API_URL + '/get_category?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    let new_category_options = [];
                    for (let i = 0; i < data['result'].length; i++) {
                        new_category_options.push({
                            value: data['result'][i]['code'],
                            label: data['result'][i]['name'],
                        })
                    }
                    console.log(new_category_options);
                    set_category_options(new_category_options);
                }
                else if (data['status'] === 401) {
                    props.handleLogout()
                }
            })
    }


    ///////////////////////////////////////////////////////////////////////////////////
    //設定region的options欄位
    const [region_options, set_region_options] = useState([]);

    const region_options_onSearch = (searchText) => {
        fetch(process.env.REACT_APP_API_URL + '/get_region?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    let new_region_options = [];
                    for (let i = 0; i < data['result'].length; i++) {
                        new_region_options.push({
                            value: data['result'][i]['id'],
                            label: data['result'][i]['name'],
                        })
                    }
                    set_region_options(new_region_options);
                }
            })
    }




    ///////////////////////////////////////////////////////////////////////////////////
    //設定location的options欄位
    const [location_options, set_location_options] = useState([]);

    const location_options_onSearch = (searchText) => {
        fetch(process.env.REACT_APP_API_URL + '/get_location?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    let new_location_options = [];
                    for (let i = 0; i < data['result'].length; i++) {
                        new_location_options.push({
                            value: data['result'][i]['id'],
                            label: data['result'][i]['location_name'],
                        })
                    }
                    set_location_options(new_location_options);
                }
            })
    }




    ///////////////////////////////////////////////////////////////////////////////////
    //查詢設備資料
    const [select_equipment_data, set_select_equipment_data] = useState([]);

    const select_equipment_id = (searchText) =>
        fetch(process.env.REACT_APP_API_URL + '/get_equipment_profile?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_select_equipment_data(data['result']);
                }
            })

    ///////////////////////////////////////////////////////////////////////////////////
    // 勾選Table的資料時的動作
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    ///////////////////////////////////////////////////////////////////////////////////
    //更改value值
    const change_equipment_profile_type_value = (e) => {
        console.log(e);
        switch (e) {
            case '線材':
            case 'AAA':
                return 2;

            case '貴重物品':
            case 'BBB':
                return 3;

            case '攝影器材':
            case 'CCC':
                return 4;

            case '其他':
            case 'DDD':
                return 5;

            case '上課用品':
            case 'EEE':
                return 6;

            case '門禁卡':
            case 'FFF':
                return 7;

            default:
                return e
        }
    }

    //更改value值
    const change_equipment_profile_region_value = (e) => {
        
        switch (e) {
            case '建工':
                return 1;

            case '燕巢':
                return 2;

            case '其他':
                return 3;
        }
    }
    //更改value值
    const change_equipment_profile_location_value = (e) => {
        switch (e) {
            case '三層櫃':
                return 1;

            case '三層櫃':
                return 2;

            case '燕巢右側三層櫃':
                return 3;

            case '燕巢後右側三層櫃':
                return 4;

            case '燕巢右側三層櫃':
                return 5;

            case '燕巢後最左側三層櫃':
                return 6;

            case '燕巢後左側三層櫃':
                return 7;

            case '雙開櫃-上':
                return 8;

            case '雙開櫃-上':
                return 9;

            case '雙開櫃-下':
                return 10;

            case '三層櫃':
                return 11;

            case '雙開櫃-下':
                return 12;

            case '建工-防潮櫃':
                return 13;

            case '燕巢後左側三層櫃':
                return 14;

            case '燕巢-MA317A':
                return 15;

            case '燕巢-櫃台辦公桌':
                return 16;

            case '建工-防潮櫃':
                return 17;

            case '建工-防潮櫃':
                return 18;

            case '燕巢後右側三層櫃':
                return 19;

            case '燕巢-櫃台辦公桌':
                return 20;

            case '主任室壁櫃':
                return 21;
            default:
                return e;
        }
    }



    ///////////////////////////////////////modal////////////////////////////////////////////
    //點選新增按鈕時的動作
    const [new_equipment_profile_visible, set_new_equipment_profile_visible] = useState(false);
    const [new_equipment_profile_name, set_new_equipment_profile_name] = useState('');
    const [new_equipment_profile_type_id, set_new_equipment_profile_type_id] = useState(2);
    const [new_equipment_profile_location_id, set_new_equipment_profile_location_id] = useState(1);
    const [new_equipment_profile_region_name, set_new_equipment_profile_region_name] = useState(1);
    const [new_equipment_profile_accessory, set_new_equipment_profile_accessory] = useState('');

    const New_equipment_profile_page = () => {

        return (
            <div>
                <Modal
                    title="新增分類"
                    visible={new_equipment_profile_visible}
                    onOk={handle_add_Ok}
                    onCancel={handle_add_Cancel}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '80%' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>設備名稱</div>
                                <div style={{ width: '70%' }}>
                                    <Input
                                        placeholder="請輸入設備名稱"
                                        onChange={(e) => {
                                            set_new_equipment_profile_name(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>設備分類</div>
                                <div style={{ width: '70%' }}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="請選擇設備分類"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            set_new_equipment_profile_type_id(e)
                                        }}
                                        options={category_options}
                                        defaultValue={category_options[0]}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>所在地區</div>
                                <div style={{ width: '70%' }}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="請選擇所在地區"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            console.log(e);
                                            set_new_equipment_profile_region_name(e)
                                            location_options_onSearch(e)
                                        }}
                                        options={region_options}
                                        defaultValue={region_options[0]}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>所在位置</div>
                                <div style={{ width: '70%' }}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="請選擇所在位置"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            set_new_equipment_profile_location_id(e)
                                        }}
                                        options={location_options}
                                        defaultValue={location_options[0]}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>設備配件</div>
                                <div style={{ width: '70%' }}>
                                    <Input
                                        placeholder="請輸入設備配件"
                                        onChange={(e) => {
                                            set_new_equipment_profile_accessory(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
    //新增設備資料
    const handle_add_Ok = () => {
        let serialno_data = ''
        let Today = new Date();
        // console.log(new_equipment_profile_type_id);
        switch (change_equipment_profile_type_value(new_equipment_profile_type_id)) {
            case (2):
                serialno_data = 'AAA' + Today.getFullYear().toString().slice(-2) + (Today.getMonth() + 1).toString().padStart(2, '0') + Today.getDate().toString().padStart(2, '0')
                break;
            case (3):
                serialno_data = 'BBB' + Today.getFullYear().toString().slice(-2) + (Today.getMonth() + 1).toString().padStart(2, '0') + Today.getDate().toString().padStart(2, '0')
                break;
            case (4):
                serialno_data = 'CCC' + Today.getFullYear().toString().slice(-2) + (Today.getMonth() + 1).toString().padStart(2, '0') + Today.getDate().toString().padStart(2, '0')
                break;
            case (5):
                serialno_data = 'DDD' + Today.getFullYear().toString().slice(-2) + (Today.getMonth() + 1).toString().padStart(2, '0') + Today.getDate().toString().padStart(2, '0')
                break;
            case (6):
                serialno_data = 'EEE' + Today.getFullYear().toString().slice(-2) + (Today.getMonth() + 1).toString().padStart(2, '0') + Today.getDate().toString().padStart(2, '0')
                break;
            case (7):
                serialno_data = 'FFF' + Today.getFullYear().toString().slice(-2) + (Today.getMonth() + 1).toString().padStart(2, '0') + Today.getDate().toString().padStart(2, '0')
                break;
        }
        console.log(serialno_data);
        fetch(process.env.REACT_APP_API_URL + '/new_equipment_profile', {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            },
            body: JSON.stringify({
                'serialno': serialno_data,
                'name': new_equipment_profile_name,
                'accessory': new_equipment_profile_accessory,
                'location_id': new_equipment_profile_location_id,
                'category_id': change_equipment_profile_type_value(new_equipment_profile_type_id),
            })
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_new_equipment_profile_visible(false);
                    notification.success({
                        message: '新增成功',
                        description: '已成功新增分類',
                        placement: 'top',
                        duration: 2,
                        onClose: () => {
                            setTimeout(() => {
                                window.location.reload(); // 重新載入整個頁面
                            }, 1000); // 在 1000 毫秒 (1 秒) 後執行
                        },
                    });
                }
                else if (data['status'] === 204) {
                    notification.error({
                        message: '新增失敗',
                        description: '請檢查輸入的資料',
                        placement: 'top',
                    });
                }
                else if (data['status'] === 400) {
                    notification.error({
                        message: '新增失敗',
                        description: 'MSG_MISSING_FIELD',
                        placement: 'top',
                    });
                }
                else if (data['status'] === 409) {
                    notification.error({
                        message: '新增失敗',
                        description: 'MSG_type_EXISTS',
                        placement: 'top',
                    });
                }
                else {
                    notification.error({
                        message: '新增失敗',
                        description: '未知錯誤，請聯絡管理員',
                        placement: 'top',
                    });
                }
            })
    };

    const handle_add_Cancel = () => {
        set_new_equipment_profile_visible(false);
    };

    ///////////////////////////////////////modal////////////////////////////////////////////
    //點選修改按鈕時的動作
    const [update_equipment_profile_visible, set_update_equipment_profile_visible] = useState(false);
    const [update_equipment_profile_code, set_update_equipment_profile_code] = useState([]);
    const [update_equipment_profile_name, set_update_equipment_profile_name] = useState(null);
    const [update_equipment_profile_type_id, set_update_equipment_profile_type_id] = useState(2);
    const [update_equipment_profile_location_id, set_update_equipment_profile_location_id] = useState(1);
    const [update_equipment_profile_region_name, set_update_equipment_profile_region_name] = useState(1);
    const [update_equipment_profile_accessory, set_update_equipment_profile_accessory] = useState('');

    const update_equipment_profile_page = () => {

        return (
            <div>

                <Modal
                    title="修改設備"
                    visible={update_equipment_profile_visible}
                    onOk={handle_update_Ok}
                    onCancel={handle_update_Cancel}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '80%' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>設備名稱</div>
                                <div style={{ width: '70%' }}>
                                    <Input
                                        placeholder="請輸入設備名稱"
                                        onChange={(e) => {
                                            set_update_equipment_profile_name(e.target.value)
                                        }}
                                        defaultValue={selectedRows[0] ? selectedRows[0]['name'] : ''}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>設備分類</div>
                                <div style={{ width: '70%' }}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="請選擇設備分類"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            set_update_equipment_profile_type_id(e)
                                        }}
                                        options={category_options}
                                        defaultValue={selectedRows[0] ? selectedRows[0]['type_name'] : ''}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>所在地區</div>
                                <div style={{ width: '70%' }}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="請選擇所在地區"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            console.log(e);
                                            set_update_equipment_profile_region_name(e)
                                            location_options_onSearch(e)
                                        }}
                                        options={region_options}
                                        defaultValue={selectedRows[0] ? change_equipment_profile_region_value(selectedRows[0]['region_name']) : ''}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>所在位置</div>
                                <div style={{ width: '70%' }}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="請選擇所在位置"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            set_update_equipment_profile_location_id(e)
                                        }}
                                        options={location_options}
                                        defaultValue={selectedRows[0] ? change_equipment_profile_location_value(selectedRows[0]['location_name']) : ''}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '30%' }}>設備配件</div>
                                <div style={{ width: '70%' }}>
                                    <Input
                                        placeholder="請輸入設備配件"
                                        onChange={(e) => {
                                            set_update_equipment_profile_accessory(e.target.value)
                                        }}
                                        defaultValue={selectedRows[0] ? selectedRows[0]['accessory'] : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
    //修改分類資料
    const handle_update_Ok = () => {
        fetch(process.env.REACT_APP_API_URL + '/update_equipment_profile', {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            },
            body: JSON.stringify({
                'serialno': selectedRows[0]['serialno'],
                'name': update_equipment_profile_name ? update_equipment_profile_name : selectedRows[0]['name'],
                'accessory': update_equipment_profile_accessory,
                'location_id': change_equipment_profile_location_value(update_equipment_profile_location_id),
                'category_id': change_equipment_profile_type_value(update_equipment_profile_type_id),
            })
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_update_equipment_profile_visible(false);
                    notification.success({
                        message: '修改成功',
                        description: '已成功修改分類',
                        placement: 'top',
                        onClose: () => {
                            setTimeout(() => {
                                window.location.reload(); // 重新載入整個頁面
                            }, 1000); // 在 1000 毫秒 (1 秒) 後執行
                        },
                    });
                }
                else if (data['status'] === 204) {
                    notification.error({
                        message: '修改失敗',
                        description: '請檢查輸入的資料',
                        placement: 'top',
                    });
                }
                else {
                    notification.error({
                        message: '修改失敗',
                        description: '未知錯誤，請聯絡管理員',
                        placement: 'top',
                    });
                }
            })
    };

    const handle_update_Cancel = () => {
        set_update_equipment_profile_visible(false);
    };

    ///////////////////////////////////////modal////////////////////////////////////////////
    //點選刪除按鈕時的動作
    const [delete_equipment_profile_visible, set_delete_equipment_profile_visible] = useState(false);

    const delete_equipment_profile_page = () => {

        return (
            <div>
                <Modal
                    title="刪除設備"
                    visible={delete_equipment_profile_visible}
                    onOk={handle_delete_Ok}
                    onCancel={handle_delete_Cancel}>
                    <p>確定刪除該設備</p>
                </Modal>
            </div>
        )
    }
    //刪除分類
    const handle_delete_Ok = () => {
        fetch(process.env.REACT_APP_API_URL + '/delete_equipment_profile', {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            },
            body: JSON.stringify({
                'serialno': selectedRows.map((item) => {
                    return item['serialno']
                }
                ),
            })
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_delete_equipment_profile_visible(false);
                    notification.success({
                        message: '刪除成功',
                        description: '已成功刪除設備',
                        placement: 'top',
                        duration: 2,
                        onClose: () => {
                            setTimeout(() => {
                                window.location.reload(); // 重新載入整個頁面
                            }, 1000); // 在 1000 毫秒 (1 秒) 後執行
                        },
                    });
                }
                else if (data['status'] === 204) {
                    notification.error({
                        message: '刪除失敗',
                        description: '請檢查輸入的資料',
                        placement: 'top',
                    });
                }
                else {
                    notification.error({
                        message: '刪除失敗',
                        description: '未知錯誤，請聯絡管理員',
                        placement: 'top',
                    });
                }
            })
    };

    const handle_delete_Cancel = () => {
        set_delete_equipment_profile_visible(false);
    };




    ///////////////////////////////////////製作條碼////////////////////////////////////////////
    //條碼儲存陣列
    const [barcodes, set_barcodes] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    //產生條碼
    const generate_barcode = () => {
        const new_barcodes = []; // 複製陣列以便進行變更
        for (let i = 0; i < selectedRows.length; i++) {
            new_barcodes.push({
                'serialno':selectedRows[i]['serialno'],
                'name':selectedRows[i]['name'],
            }); // 將條碼加入新的陣列中
        }
        set_barcodes(new_barcodes); // 更新狀態
        setPrint(true); // 印出條碼
    };

    const handleDownloadImage = async () => {
        const element = componentRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = 'image.jpg';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            set_barcodes([]); // 更新狀態

        } else {
            window.open(data);
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const componentRef = useRef();


    const BarcodesToPrint = React.forwardRef(({ barcodes }, ref) => (
        <div ref={ref} style={{ visibility: 'visible', margin: 0, padding: 0 }}>
            {barcodes.map((data, index) => (
                <div key={index} style={{ margin: 0, padding: 0, textAlign: 'center', alignContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div>{data['name']}</div>
                        <Barcode
                            value={data['serialno']}
                            height={25}
                            width={2} />
                    </div>
                    <br />
                </div>
            ))}
        </div>
    ));


    //異部處理條碼彈出視窗
    const [print, setPrint] = useState(false);

    useEffect(() => {
        if (print) {
            if (selectedRows.length === 0) {
                notification.error({
                    message: '請先選擇要印出的條碼',
                    description: '請先選擇要印出的條碼',
                    placement: 'top',
                });
                setPrint(false);
                return;
            }
            else {
                // handlePrint();
                handleDownloadImage();
                setPrint(false);
            }
        }
    }, [barcodes, print, handlePrint]);

    ///////////////////////////////////////製作條碼end////////////////////////////////////////////

    ///////////////////////////////////////modal////////////////////////////////////////////
    //modal
    const [handle_type_visible, set_handle_type_visible] = useState(false);

    const handle_type_modal_click = () => {
        set_handle_type_visible(true);
    };



    const [handle_location_visible, set_handle_location_visible] = useState(false);

    const handle_location_modal_click = () => {
        set_handle_location_visible(true);
    };


    ///////////////////////////////////////前端////////////////////////////////////////////
    //畫面HTML
    return (
        <div style={{
            display: 'flow',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%'
        }}>
            <div>
                <br />
                關鍵字：
                <AutoComplete
                    style={{
                        width: '22%',
                        marginRight: '25%'
                    }}
                    placeholder="可輸入設備ID、設備名稱"
                    onChange={select_equipment_id}
                />
                <Button size={'small'} style={{ marginRight: '1%' }} onClick={handle_type_modal_click}><ToolTwoTone />分類管理</Button>
                <Button size={'small'} style={{ marginRight: '1%' }} onClick={handle_location_modal_click}><ToolTwoTone />位置管理</Button>
                <Button size={'small'} style={{ marginRight: '1%' }} onClick={generate_barcode}>
                    <PlusSquareTwoTone />製作條碼
                </Button>

                <Button size={'small'} style={{ marginRight: '1%' }} onClick={() => set_new_equipment_profile_visible(true)}><PlusSquareTwoTone />新增</Button>
                <Button size={'small'} style={{ marginRight: '1%' }} onClick={() => set_update_equipment_profile_visible(true)}><EditTwoTone />修改</Button>
                <Button size={'small'} style={{ marginRight: '1%' }} onClick={() => set_delete_equipment_profile_visible(true)}><CloseSquareTwoTone />刪除</Button>
                {New_equipment_profile_page()}
                {update_equipment_profile_page()}
                {delete_equipment_profile_page()}

                <Type_management_page handle_type_visible={handle_type_visible} set_handle_type_visible={set_handle_type_visible} />
                <Location_management_page handle_location_visible={handle_location_visible} set_handle_location_visible={set_handle_location_visible} />

            </div>
            <br />
            <br />
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Card
                        // title="欲借設備："
                        size='small'
                        style={{
                            width: '100%',
                        }}
                    >

                        <Table
                            rowKey="serialno"
                            columns={columns}
                            dataSource={select_equipment_data}
                            rowSelection={{
                                ...rowSelection,
                            }}
                            onSelect={(record, selected, selectedRows) => {
                                if (selected) {
                                    generate_barcode(record.serialno);
                                } else {
                                    set_barcodes([]); // 清空條碼
                                }
                            }}

                        />
                    </Card>
                </div>
            </div>
            <div style={{ visibility: 'hidden' }}>
                <BarcodesToPrint ref={componentRef} barcodes={barcodes} />
            </div>
        </div>
    )
};
export default App;
