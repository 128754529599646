import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification } from 'antd';
import cookie from 'react-cookies'
import sha512 from 'crypto-js/sha512';

const ChangePasswordForm = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        select_account_profile('')
    }, [])

    ///////////////////////////////////////////////////////////////////////////////////
    //查詢使用者資料

    const select_account_profile = (searchText) => {
        fetch(process.env.REACT_APP_API_URL + '/get_account_profile?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                console.log(data)
                //使用form.setFieldsValue更新表单组件的值
                form.setFieldsValue({
                    username: data['result']
                })
            })
    }

    // 提交表單
    const handleSubmit = async (values) => {
        fetch(process.env.REACT_APP_API_URL + '/update_password', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            },
            body: JSON.stringify({
                username: values.username,
                oldPassword: sha512(values.oldPassword).toString(),
                newPassword: sha512(values.newPassword).toString(),
                confirmPassword: sha512(values.confirmPassword).toString(),

            })
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                console.log(data)
                if( data['status'] === 200 ){
                    notification.success({
                        message: '修改成功',
                        description: '已成功修改密碼，請重新登入',
                        placement: 'top',
                        onClose: () => {
                            props.handleLogout()
                        },
                    });
                }
                else{
                    notification.error({
                        message: data['message'],
                        description: data['result'],
                        placement: 'top',
                    });
                }
            })
    };

    return (
        <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleSubmit}
        >
            <Form.Item
                name="username"
                label="帳號"
            >
                <Input disabled={true} style={{width:'50%'}}/>
            </Form.Item>
            <Form.Item
                name="oldPassword"
                label="舊密碼"
                rules={[{ required: true, message: '舊密碼不能為空' }]}
            >
                <Input.Password placeholder="請輸入舊密碼"  style={{width:'50%'}}/>
            </Form.Item>
            <Form.Item
                name="newPassword"
                label="新密碼"
                rules={[
                    { required: true, message: '新密碼不能為空' },
                ]}
            >
                <Input.Password placeholder="請輸入新密碼"  style={{width:'50%'}}/>
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label="確認密碼"
                dependencies={['newPassword']}
                rules={[
                    { required: true, message: '確認密碼不能為空' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('兩次輸入的密碼不一致'));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="請再次輸入新密碼"  style={{width:'50%'}}/>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ChangePasswordForm;