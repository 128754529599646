import React  from 'react';
import { Card, AutoComplete, Table } from 'antd';
import { useState, useEffect } from 'react';
import cookie from 'react-cookies'

const App = (props) => {
    
    ///////////////////////////////////////////////////////////////////////////////////
    //進入畫面時的動作，先查詢一次資料
    useEffect (() => {
        select_history('')
    },[])


    ///////////////////////////////////////////////////////////////////////////////////
    //設定Table的欄位

    const columns = [
        {
            title: '借用時間',
            dataIndex: 'borrow_time',
            key: 'borrow_time',
        },
        {
            title: '歸還時間',
            dataIndex: 'return_time',
            key: 'return_time',
        },
        {
            title: '設備名稱',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '借用人',
            key: 'user_name',
            dataIndex: 'user_name',
        },
        {
            title: '借用狀態',
            key: 'status',
            dataIndex: 'status',
        },
    ];

    ///////////////////////////////////////////////////////////////////////////////////
    //查詢設備資料
    const [select_equipment_data, set_select_equipment_data] = useState([]);

    const select_history = (searchText) =>
        fetch(process.env.REACT_APP_API_URL + '/get_equipment_history?searchText=' + searchText, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'jwtToken': cookie.load('jwtToken'),
            }
        })
            .then(res => {
                return res.json()
            }) /*把request json化*/
            .then(data => {
                /*接到request data後要做的事情*/
                if (data['status'] === 200) {
                    set_select_equipment_data(data['result']);
                }
                else if (data['status'] === 401) {
                    props.handleLogout()
                }
            })

    ///////////////////////////////////////////////////////////////////////////////////
    return (
        <div style={{
            display: 'flow',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%'
        }}>
            <div>
                關鍵字：
                <AutoComplete
                    style={{
                        width: "22%",
                    }}
                    placeholder="可輸入學號、姓名、設備ID、設備名稱"
                    onChange={select_history}
                />
            </div>
            <br />
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Card
                        // title="欲借設備："
                        size='small'
                        style={{
                            width: '100%',
                        }}
                    >
                        <Table columns={columns} dataSource={select_equipment_data} />
                    </Card>
                </div>
            </div>
        </div>
    )
};
export default App;
